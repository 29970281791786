var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("drivers.driver-edit"),
          backButtonText: _vm.$t("navigation.back"),
          actionButtonIcon: "far fa-trash-alt",
          actionButtonColor: "red",
          actionButtonText: _vm.$t("driver-remove")
        },
        on: { action: _vm.removeDriver }
      }),
      _c(
        "el-form",
        {
          ref: "driverForm",
          staticClass: "driver-container",
          attrs: {
            model: _vm.driverData,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "120px"
          }
        },
        [
          _c("h5", [_vm._v(_vm._s(_vm.$t("drivers.driver-edit")))]),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "driverType",
                label: _vm.$t("drivers.driver-type")
              }
            },
            [
              _c("el-select", {
                attrs: {
                  disabled: "",
                  placeholder: _vm.$t("drivers.select-driver-type")
                },
                model: {
                  value: _vm.driverData.driverType,
                  callback: function($$v) {
                    _vm.$set(_vm.driverData, "driverType", $$v)
                  },
                  expression: "driverData.driverType"
                }
              })
            ],
            1
          ),
          _vm.showConnectionType(_vm.driverData.driverType)
            ? _c(
                "el-form-item",
                { attrs: { prop: "connectionType", label: "Connection Type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Connection Type" },
                      model: {
                        value: _vm.driverData.connectionType,
                        callback: function($$v) {
                          _vm.$set(_vm.driverData, "connectionType", $$v)
                        },
                        expression: "driverData.connectionType"
                      }
                    },
                    _vm._l(_vm.modbusConnectionTypes, function(type) {
                      return _c("el-option", {
                        key: type.value,
                        attrs: { label: type.title, value: type.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.driverData.driverType == _vm.DRIVER_TYPE_MODBUS &&
          _vm.showIpInputModbus(_vm.driverData.connectionType)
            ? _c("table", [
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { tyle: "width:80%" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "ipAddress",
                            label: _vm.$t("drivers.ip-address")
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: _vm.ipMask,
                                expression: "ipMask"
                              }
                            ],
                            attrs: {
                              s: "",
                              type: "text",
                              "auto-complete": "on",
                              placeholder: _vm.$t("drivers.type-ip-address")
                            },
                            model: {
                              value: _vm.driverData.ipAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.driverData, "ipAddress", $$v)
                              },
                              expression: "driverData.ipAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticStyle: { width: "20%" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "0",
                            prop: "port",
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("drivers.rule-port")
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              min: "0",
                              max: "65535",
                              type: "number",
                              placeholder: _vm.$t("drivers.port")
                            },
                            on: { change: _vm.onPortChanged },
                            model: {
                              value: _vm.driverData.port,
                              callback: function($$v) {
                                _vm.$set(_vm.driverData, "port", $$v)
                              },
                              expression: "driverData.port"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm.showConnector(
            _vm.driverData.driverType,
            _vm.driverData.connectionType
          )
            ? _c(
                "el-form-item",
                { attrs: { label: "Connector" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Connector" },
                      model: {
                        value: _vm.driverData.connector,
                        callback: function($$v) {
                          _vm.$set(_vm.driverData, "connector", $$v)
                        },
                        expression: "driverData.connector"
                      }
                    },
                    _vm._l(_vm.modbusConnectors, function(connector) {
                      return _c("el-option", {
                        key: connector.value,
                        attrs: {
                          label: connector.title,
                          value: connector.value
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showBaudrate(
            _vm.driverData.driverType,
            _vm.driverData.connectionType
          )
            ? _c(
                "el-form-item",
                { attrs: { label: "BaudRate" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "BaudRate" },
                      model: {
                        value: _vm.driverData.baudRate,
                        callback: function($$v) {
                          _vm.$set(_vm.driverData, "baudRate", $$v)
                        },
                        expression: "driverData.baudRate"
                      }
                    },
                    _vm._l(_vm.baudrates, function(baudRate) {
                      return _c("el-option", {
                        key: baudRate,
                        attrs: { label: baudRate, value: baudRate }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showIpAddress(_vm.driverData.driverType)
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "ipAddress",
                    label: _vm.$t("drivers.ip-address")
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: { mask: "9[9][9].9[9][9].9[9][9].9[9][9]" },
                          expression:
                            "{'mask': '9[9][9].9[9][9].9[9][9].9[9][9]'}"
                        }
                      ],
                      attrs: {
                        type: "text",
                        "auto-complete": "on",
                        placeholder: _vm.$t("drivers.type-ip-address")
                      },
                      model: {
                        value: _vm.driverData.ipAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.driverData, "ipAddress", $$v)
                        },
                        expression: "driverData.ipAddress"
                      }
                    },
                    [
                      _vm.getPort(_vm.driverData.driverType) > 0
                        ? _c("template", { slot: "append" }, [
                            _vm._v(
                              ":" +
                                _vm._s(_vm.getPort(_vm.driverData.driverType))
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    icon: "el-icon-edit"
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitDriverForm($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("drivers.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }