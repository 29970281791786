<template>
  <div>

    <app-navigation :title="$t('drivers.driver-edit')" :backButtonText="$t('navigation.back')"
                    actionButtonIcon="far fa-trash-alt" actionButtonColor="red" :actionButtonText="$t('driver-remove')" @action="removeDriver">
    </app-navigation>

    <el-form :model="driverData" ref="driverForm" :rules="rules" class="driver-container" label-position="left" label-width="120px">
      <h5>{{ $t('drivers.driver-edit') }}</h5>

      <el-form-item prop="driverType" :label="$t('drivers.driver-type')">
        <el-select disabled v-model="driverData.driverType" :placeholder="$t('drivers.select-driver-type')">
         <!--<el-option v-for="type in driverTypes" :label="type.title" :value="type.value" :key="type.value"></el-option>-->
        </el-select>
      </el-form-item>

      <el-form-item prop="connectionType" label="Connection Type" v-if="showConnectionType(driverData.driverType)">	
        <el-select v-model="driverData.connectionType" placeholder="Connection Type">	
          <el-option v-for="type in modbusConnectionTypes" :label="type.title" :value="type.value" :key="type.value">
          </el-option>	
        </el-select>	
      </el-form-item>     
      
      <table v-if="driverData.driverType == DRIVER_TYPE_MODBUS && showIpInputModbus(driverData.connectionType)">	
        <tr>	
          <td tyle="width:80%">	
            <el-form-item  prop="ipAddress" :label="$t('drivers.ip-address')" >	
                <el-input s type="text" v-model="driverData.ipAddress" auto-complete="on" :placeholder="$t('drivers.type-ip-address')" v-mask="ipMask"/>	
            </el-form-item>              	
          </td>           	
          <td style="width:20%" > 	
            <el-form-item label-width="0" prop="port" :rules="[{required: true, message: $t('drivers.rule-port')}]">	
                <el-input min="0" max="65535" type="number" @change="onPortChanged" v-model="driverData.port" :placeholder="$t('drivers.port')" />	
            </el-form-item>             	
          </td>	
        </tr>	
      </table>    

      <el-form-item label="Connector" v-if="showConnector(driverData.driverType, driverData.connectionType)">	
        <el-select v-model="driverData.connector" placeholder="Connector">	
          <el-option v-for="connector in modbusConnectors" :label="connector.title" :value="connector.value" :key="connector.value">
          </el-option>	
        </el-select>	
      </el-form-item>     

      <el-form-item label="BaudRate" v-if="showBaudrate(driverData.driverType, driverData.connectionType)">	
        <el-select v-model="driverData.baudRate" placeholder="BaudRate">	
          <el-option v-for="baudRate in baudrates" :label="baudRate" :value="baudRate" :key="baudRate">	
          </el-option>	
        </el-select>	
      </el-form-item>             

      <el-form-item prop="ipAddress" :label="$t('drivers.ip-address')" v-if="showIpAddress(driverData.driverType)">
        <el-input type="text" v-model="driverData.ipAddress" auto-complete="on" :placeholder="$t('drivers.type-ip-address')" v-mask="{'mask': '9[9][9].9[9][9].9[9][9].9[9][9]'}">
          <template v-if="getPort(driverData.driverType) > 0" slot="append">:{{ getPort(driverData.driverType) }}</template>
        </el-input>
      </el-form-item>

      <el-form-item style="text-align: left;">
        <el-button type="primary" @click.native.prevent="submitDriverForm" :loading="loading" icon="el-icon-edit">{{ $t('drivers.save') }}</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import {
  DRIVER_TYPE_MODBUS,
  showIpAddress,
  getPort,
  showConnector,
  showBaudrate,
  connectors,
  baudrates,
  connectionTypes,
  showConnectionType,
  showIpInputModbus,
  CONNECTION_RTU,
  RS232,
  RS485,
} from "@/utils/driver-utils";
import Navigation from "@/components/Navigation";
import { driverMixin } from "./driverMixin";

export default {
  name: "DriverEdit",

  components: {
    "app-navigation": Navigation,
  },

  mixins: [driverMixin],

  data: function() {
    return {
      driverData: {
        id: null,
        ipAddress: null,
        port: null,
        driverType: null,
        extraData: null,
        connector: null,
        connectionType: null,
        baudRate: null,
      },
      connectors,
      baudrates,
      connectionTypes,
      loading: false,
      DRIVER_TYPE_MODBUS,
      ipMask: {
        mask: "9{1,3}.9{1,3}.9{1,3}.9{1,3}",
      },
    };
  },

  computed: {
    modbusConnectionTypes: function() {
      let existsRS232 = this.$store.getters.existsRS232 || true;
      let existsRS485 = this.$store.getters.existsRS485 || true;

      let rtuDrivers = this.$store.getters.drivers
        .filter((driver) => driver.driverType == DRIVER_TYPE_MODBUS)
        .filter((driver) => {
          let extraData = JSON.parse(driver.extraData);
          return extraData.connectionType == CONNECTION_RTU;
        });

      let rtuCount = rtuDrivers.length;

      if (this.driverData.extraData) {
        let data = JSON.parse(this.driverData.extraData);
        if (data.connectionType == CONNECTION_RTU) rtuCount--;
      }

      if ((!existsRS232 && !existsRS485) || rtuCount == 2)
        return this.connectionTypes.filter(
          (type) => type.value != CONNECTION_RTU
        );
      else return this.connectionTypes;
    },

    modbusConnectors: function() {
      let existsRS232 = this.$store.getters.existsRS232 || true;
      let existsRS485 = this.$store.getters.existsRS485 || true;

      let rtuDrivers = this.$store.getters.drivers
        .filter((driver) => driver.driverType == DRIVER_TYPE_MODBUS)
        .filter((driver) => {
          let data = JSON.parse(driver.extraData);
          return data.connectionType == CONNECTION_RTU;
        });

      if (existsRS232) {
        existsRS232 =
          rtuDrivers.filter((driver) => {
            let data = JSON.parse(driver.extraData);
            return data.connector == RS232;
          }).length == 0;
      }

      if (existsRS485) {
        existsRS485 =
          rtuDrivers.filter((driver) => {
            let data = JSON.parse(driver.extraData);
            return data.connector == RS485;
          }).length == 0;
      }

      if (this.driverData.extraData) {
        let data = JSON.parse(this.driverData.extraData);
        if (data.connector == RS232) existsRS232 = true;
        else if (data.connector == RS485) existsRS485 = true;
      }

      console.log("connectors", existsRS232, existsRS485, rtuDrivers);

      if (!existsRS232)
        return this.connectors.filter((conn) => conn.value != RS232);
      else if (!existsRS485)
        return this.connectors.filter((conn) => conn.value != RS485);
      else return this.connectors;
    },
  },

  methods: {
    showIpAddress,
    showConnectionType,
    showConnector,
    showBaudrate,
    showIpInputModbus,
    getPort,

    setExtraData() {
      if (this.driverData.driverType === DRIVER_TYPE_MODBUS) {
        let ed = JSON.parse(this.driverData.extraData);
        this.driverData.connector = ed.connector;
        this.driverData.connectionType = ed.connectionType;
        this.driverData.baudRate = ed.baudRate;
      }
    },

    removeDriver() {
      this.$confirm(
        this.$t("alert.message.confirm-delete"),
        this.$t("alert.title.warning"),
        {
          confirmButtonText: this.$t("alert.button.ok"),
          cancelButtonText: this.$t("alert.button.cancel"),
          type: "warning",
        }
      ).then(() => {
        this.$store
          .dispatch("removeDriver", this.driverData.id)
          .then((response) => {
            this.$router.go(-1);
            this.$message({
              message: this.$t(response.message),
              type: "success",
            });
          })
          .catch((reason) => {
            this.$message({
              message: this.$t(reason),
              type: "error",
            });
          });
      });
    },

    submitDriverForm() {
      this.$refs.driverForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.driverData.driverType == DRIVER_TYPE_MODBUS) {
            this.driverData.extraData = {};
            this.driverData.extraData = JSON.stringify({
              connectionType: this.driverData.connectionType,
              connector: this.driverData.connector,
              baudRate: this.driverData.baudRate,
            });
          }
          //this.driverData.ipAddress = this.driverData.ipAddress.replace(/_/g, '')

          this.$store
            .dispatch("editDriver", this.driverData)
            .then((response) => {
              this.loading = false;
              this.$message({
                message: this.$t(response.message),
                type: "success",
              });

              this.$router.go(-1);
            })
            .catch((reason) => {
              this.loading = false;
              this.$message({
                message: this.$t(reason),
                type: "error",
              });
            });
        }
      });
    },
  },

  created() {
    var data = this.$store.getters.drivers.find(
      (driver) => driver.id == this.$route.params.id
    );
    if (!this.driverData) {
      this.loading = true;
      this.$store
        .dispatch("getDrivers")
        .then((message) => {
          this.loading = false;
          data = this.$store.getters.drivers.find(
            (driver) => driver.id == this.$route.params.id
          );

          this.driverData.id = data.id;
          this.driverData.ipAddress = data.ipAddress;
          this.driverData.port = data.port;
          this.driverData.driverType = data.driverType;
          this.driverData.extraData = data.extraData;

          this.setExtraData();
        })
        .catch((reason) => {
          this.loading = false;
        });
    } else {
      this.driverData.id = data.id;
      this.driverData.ipAddress = data.ipAddress;
      this.driverData.port = data.port;
      this.driverData.driverType = data.driverType;
      this.driverData.extraData = data.extraData;
      this.setExtraData();
    }
  },
};
</script>

<style scoped>
.driver-container {
  margin-left: 20px;
  background: #fff;
  border-radius: 10px;
  margin-right: 20px;
  padding: 15px 25px 15px 25px;
  border: 1px solid #eaeaea;
  width: 480px;
}
</style>
